import restaurant from "./images/rest.jpg";
import img_card from "./images/7.png";
import imgheaderrestaurant from "./images/welcom.jpg";
import imgheaderrestaurantmb from "./images/welcom2.jpg";

import imgheader from "./images/header.webp";

import imgheaderm from "./images/headermb.webp";
import img2 from "./images/img2.jpg";
import imgheadermiini from "./images/ring.webp";
import imgheadermiinimb from "./images/ring.webp";

{/*valeriacosarca@gmail.com*/}

const data = {
    introData: [{
        title: "Invitatie Online",
        mire: "Daniel",
        mireasa: "Valeria",
        data: "2 August 2024",
        data_confirmare: "15 iulie 2024",
        savedata: "~ Salvează Data ~",
        imgdesktop: imgheader,
        imgmobil: imgheaderm,
        email: "valeriabuzdugan331@gmail.com", 
       tel: "+37361105408",
       phone: "tel:+37361105408",
       viber: "viber://chat?number=%2B37361105408",
       whatsapp: "https://wa.me/+37361105408",
       messenger: "https://www.messenger.com/t/valeria.buzdugan.9",
       tel1: "+373xxx",
       phone1: "tel:+373xxx",
       viber1: "viber://chat?number=%2B373xxx",
       whatsapp1: "https://wa.me/+373xxx",
       messenger1: "https://www.messenger.com/t/xx",
    }],
    cardData: [
        {
            id: 1,
            img: restaurant,
            title: "Locația Evenimentului",
            name: "Casa de cultură",
            data: "2 august 2024, vineri, ora 17:00",
            adress: "Satul Cobîlea, Raionul Șoldănești",
            harta: "https://maps.app.goo.gl/MraLGZD6aeMb49sFA",
            iframe: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2226.018587339263!2d28.665824909270533!3d47.863638900647295!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40cc63760233179f%3A0xf30ae7cb503aaf2!2sPrim%C4%83ria%20Cobilea!5e1!3m2!1sro!2s!4v1714216597421!5m2!1sro!2s"  
        }

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "Ne căsătorim!",
            title2: "VĂ INVITĂM LA NUNTA NOASTRĂ!",
            message: "În ziua în care vom celebra căsătoria nostră, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a vă invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
            ps: "PS. Vă vom fi recunoscători dacă tradiționalul buchet de flori va fi înlocuit cu un cadou simbolic în semn de recunoștință.",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: img2,
            title1: "Invitație",
            title2: "Nunta noastră!",
            message: "In ziua în care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a va invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
            ps: 'PS. Vă vom fi recunoscători dacă tradiționalul buchet de flori va fi înlocuit cu o carte sau cu o sticlă de vin în semn de recunoștință.' 
        }
    ],
    blogDataOmSuflet: [
        {

            img: imgheaderrestaurant,
            img2: imgheaderrestaurantmb,
            title: "Nu uitați să luați cu voi un car de voie bună și opinci de dansat!!!",
            message: "P.S: Vom fi recunoscători pentru confirmarea prezenței DVS!",
            nasii: "",
            nasii_nume: "",
        }
    ],
    blogDataDrum: [
        {
           
            img: imgheadermiini,
          
            title: "Oameni de suflet!",
            message: "Alături de care vom scrie povestea noastră.",
            message1: "CU BINECUVÂNTAREA PĂRINȚILOR",
            parintii_nume1: "Lorin & Angela ",
            parintii_nume2: "Alexandru & Liliana ",
            parintii_nume3: "",
            parintii_nume4: "",
            nasii: "ȘI SUSȚINEREA NAȘILOR",
            nasii_nume: "Valeriu & Daniela ",
        }
    ],

}

export default data;