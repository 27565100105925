import React from "react";



import { ParallaxProvider } from 'react-scroll-parallax';
import '../css/Timer.css';
import '../css/Body.css';
import Timer from "./Timer";
import Parallaximg from "../Parallaximg";
import FavoriteIcon from '@mui/icons-material/Favorite';
import { red } from "@mui/material/colors";
import CardLocal from "../cardLocal";
import FormSection from "../FormSection";
import Gallery from "./Gallery";
import BlogSectionNoi from "../blogSectionNoi";
import BlogSectionNasii from "../blogSectionNasii";
import data from "../Data";
import music from '../images/music.png';
import muz from '../video/TOTDEUNA.mp3'
import ring from '../images/giphy.gif'
import Suspendare from "./Suspendare";

const Home = (props) => {


    return (



        <ParallaxProvider>





            {data.introData.map((item) => {
                return (
                    <Parallaximg imgsrc={window.innerWidth >= 850 ? item.imgdesktop : item.imgmobil} height="600px" opacity=".5" >
                        <div className="mask" > </div>
                        <div className="row text-center align-items-center" style={{ position: 'relative' }}>
                            <div className="col-12 "style={{ maxWidth: '600px', padding: '30px', zIndex: '3'}} >
                                <h1 style={{ fontSize: '60px' }}>{item.mire} & {item.mireasa}</h1>
                                <h4 style={{ fontSize: '36px' }}>{item.savedata}<br />{item.data}</h4>
                                <Timer />
                            </div>
                        </div>
                    </Parallaximg>
                )
            })}





<div className="text-center mt-4 pt-4"style={{ maxWidth: '650px', padding: '30px', margin: 'auto' }} >
                <span className="text-center">
                    <img className="music animate__animated animate__pulse animate__delay-4s animate__infinite" src={music} />
                    <audio src={muz} controls autoPlay loop ></audio>
                </span>
            
                                <h1 style={{ fontSize: '45px', color: 'rgb(30, 30, 30)' }}>De aici începe totul.</h1>
                                <h4 style={{ color: 'rgb(30, 30, 30)' }}>"Dragostea constă în dorința de a da ceea ce este al tău altuia și de a simți fericirea acestuia ca și cum ar fi a ta." – Emanuel Swdenborg"</h4>
                            
            </div>




            <BlogSectionNoi />
 <Gallery /> 

            {data.blogDataDrum.map((item) => {
                return (
                    <Parallaximg imgsrc={item.img} height="600px" opacity=".2">
                        <div className="mask" > </div>
                       <div className="row text-center align-items-center mx-lg-5" style={{ position: 'relative' }}>
                            <div className="col-12" style={{ maxWidth: '900px', padding: '20px', zIndex: '3'  }}>
                                <h1 style={{ fontSize: '55px' }}>{item.title}</h1>
                                <h4 className="mb-4">{item.message}</h4>
                                <h5 className="mb-4">{item.message1}</h5>
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                    <h4 style={{ fontSize: '25px' }}><b>{item.parintii_nume1}</b></h4>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                    <h4 style={{ fontSize: '25px' }}><b>{item.parintii_nume2}</b></h4>
                                    </div>
                                </div>
                                <FavoriteIcon className="music animate__animated animate__pulse animate__delay-4s animate__infinite" sx={{ color: red[800], fontSize: 40 }} />
                                <h4 className="mb-4 mt-3" style={{ fontSize: '20px' }} >{item.nasii}</h4>
                                <h4 style={{ fontSize: '25px' }}><b>{item.nasii_nume}</b></h4>
                            </div>
                        </div>
                    </Parallaximg>
                )
            })} 


           


  

       <CardLocal />


         {data.blogDataOmSuflet.map((item) => {
                return (
                    <Parallaximg imgsrc={window.innerWidth >= 850 ? item.img : item.img2} height="400px" opacity=".2">
                    <div className="mask" > </div>
                        <div className="row text-center align-items-center" style={{ position: 'relative' }}>
                            <div className="col-12 " style={{ maxWidth: '650px', padding: '30px', zIndex: '3' }}>
                                <h1 >{item.title}</h1>
                                 <FavoriteIcon sx={{ color: red[800], fontSize: 40 }} />
                                <h4 className="mb-4">{item.message}</h4>
                               
                                <h4 className="mb-4"style={{ fontSize: '20px' }} >{item.nasii}</h4>
                                <h1 style={{ fontSize: '30px' }}>{item.nasii_nume}</h1>
                            </div>
                        </div>
                    </Parallaximg>
                )
            })}


        

             
            

            <FormSection />

            {data.introData.map((item) => {
                return (
                    <div>
                        <h4 className="text-center mt-5" >
                            Vă așteptăm cu drag!
                        </h4>

                        <h3 className="text-center mb-5" style={{ fontFamily: 'Dancing Script', fontSize: '30px' }}>
                            {item.mire} & {item.mireasa} <br/>
                            <img  id="svg" src={ring} style={{width: '100px'}}/>
                        </h3>
                    </div>

                )
            })}





        </ParallaxProvider>



    )
}

export default Home;